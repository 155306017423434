import React from "react"
import styles from "./styles.module.scss"
import NewsCarousel from "../NewsCarousel"

const NewsArticle = ({ article }) => {
  var date = new Date(Date.parse(article.date))
  var locationAndDate =
    (article.location ? article.location + ", " : "") +
    date.toLocaleString("default", {
      month: "long",
      year: "numeric",
      day: "numeric",
    })
  return (
    <div>
      <div id={styles.page}>
        <div id={styles.articleTitle}>{article.title}</div>
        {article.subHeadline ? (
          <p className={`body-copy ${styles.newsSubTitle}`}>
            {article.subHeadline}
          </p>
        ) : null}
        {article.body.map((paragraphs, i) => {
          return (
            <div id={styles.articleBody}>
              {i === 0 ? (
                <text className={`${styles.newsBold}`}>
                  {" " + locationAndDate + " - "}
                </text>
              ) : null}
              {paragraphs.paragraph}
            </div>
          )
        })}
      </div>
      <NewsCarousel />
    </div>
  )
}

export default NewsArticle
