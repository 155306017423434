import React from "react"
import NewsArticle from "../components/NewsArticles/news-article"

function News(args) {
  return (
    <>
      <div>
        <NewsArticle article={args.pageContext} />
      </div>
    </>
  )
}

export default News
